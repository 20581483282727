@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

html,
body,
a,
input,
textarea,
span,
div {
  font-family: "DM Sans", sans-serif;
}

@font-face {
  font-family: signature;
  src: url('./assets/aAutoSignature.ttf');
}

.dummy_one {
  background-image: url("./assets/dummy_one.png");
}

.dummy_two {
  background-image: url("./assets/dummy_two.png");
}

.dummy_three {
  background-image: url("./assets/dummy_three.png");
}


.container_hub_bg {
  background-image: url("./assets/container_hub.jpg");
}

.around_bg {
  background-image: url("./assets/aroundTheWorld.png");
}

.map_bg {
  background-image: url("./assets/map.png");
}

.alot_of_boxes {
  background-image: url("./assets/alot_of_boxes.jpg");
}

.services_bg {
  background-image: url("./assets/services-bg-1.jpg");
}

.slider_one {
  background-image: url("./assets/slider_one.jpg");
}

.image_one {
  background-image: url("./assets/container_wall_one.jpg");
}

.image_two {
  background-image: url("./assets/main_ship_one.jpg");
  /* background-position: 100% 45% !important; */
}

.image_three {
  background-image: url("./assets/slider_two_people.jpg");
}

.slider_ship_container {
  background-image: url("./assets/slider_ship_container.jpg");
}

.worlds_leading {
  background-image: url("./assets/worlds_leading.jpg");
}

/* Add these styles to your global CSS or use Tailwind's @apply if needed */


/* Add this CSS for fade-in effect */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
  /* Optional: adds a slight slide effect */
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  /* Duration and easing for the animation */
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease;
}


.global-leaders {
  position: relative;
  overflow: hidden;
}

.text-section {
  padding: 20px;
}

.signature {
  opacity: 0;
  transform: translateX(-20px);
  animation: signatureAnimation 1s forwards;
  animation-delay: 0.5s;
  /* Delay for visibility */
}

.title,
.description {
  opacity: 0;
  transform: translateY(20px);
  animation: textFadeIn 1s forwards;
}

.title {
  animation-delay: 1s;
  /* Staggered animation */
}

.description {
  animation-delay: 1.5s;
  /* Staggered animation */
}

.image-section {
  display: flex;
  justify-content: space-between;
}

.animate-image {
  opacity: 0;
  transform: translateY(20px);
  animation: imageFadeIn 1s forwards;
}

.animate-image:nth-child(1) {
  animation-delay: 0.5s;
  /* Staggered animation for first image */
}

.animate-image:nth-child(2) {
  animation-delay: 0.7s;
  /* Staggered animation for second image */
}

@keyframes signatureAnimation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes textFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes imageFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.services-container {
  position: relative;
  height: 80vh;
  /* Adjust as needed */
  background: url('./assets/gl_one.jpg') no-repeat center center / cover;
  /* Background image */
}

.services-container .grid {
  height: 100%;
}


.grid-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  /* Text color */
  font-size: 24px;
  /* Adjust text size */
  overflow: hidden;
  /* Prevent overflow from the pseudo-element */
}

.grid-item:hover {
  filter: blur(10px);
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  /* Inherit background */
  filter: blur(5px);
  /* Apply blur to the background */
  z-index: 0;
  /* Behind the text */
  transition: opacity 0.3s ease;
  /* Transition for smooth effect */
}

.grid-item:hover::before {
  opacity: 1;
  /* Show blur effect on hover */
}

.grid-item:hover {
  z-index: 1;
  /* Bring content above the blurred background */
}

.reveal-text {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hovered .reveal-text {
  transform: translateX(0);
  opacity: 1;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 10px;
}


@media (min-width: 768px) {
  .ant-form-item-explain-error {
    font-size: 14px;
  }
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: 100% !important;
}